import React from "react";
import NextIcon from "../../assets/images/icons/next.svg"
import PreviousIcon from "../../assets/images/icons/previous.svg"

class Schedule extends React.Component {
    constructor(props) {
        super(props);
        //try to find the position based on current browser date
        const today = String(new Date().getDate()).padStart(2, '0') + "." + String(new Date().getMonth() + 1).padStart(2, '0')
        let foundPosition = this.props.pages.map(p => p.date).indexOf(today)
        if (foundPosition === -1) {
            foundPosition = 0
        }
        this.state = {
            position: foundPosition
        }
    }

    nextPage = () => {
        const next = this.state.position + 1
        if (next < this.props.pages.length) {
            this.setState({
                position: next
            })
        }
    }

    previousPage = () => {
        const previous = this.state.position - 1
        if (previous >= 0) {
            this.setState({
                position: previous
            })
        }
    }

    render() {
        return (
            <div className="grid-x schedule">
                <div className="large-offset-7 large-4 cell text-right date">
                    <button onClick={this.previousPage}>
                        <img src={PreviousIcon} alt="Previous schedule page control"/>
                    </button>
                    {this.props.pages[this.state.position].date}
                    <button onClick={this.nextPage}>
                        <img src={NextIcon} alt="Next schedule page control"/>
                    </button>

                </div>
                <div className="large-12 cell">
                    {this.props.pages[this.state.position].schedule.map((item, ndx) => (
                        <div className="grid-x schedule-element" key={ndx}>
                            { !item.track && (
                                <div className="cell small-offset-1 small-1">
                                    {item.time}
                                </div>
                            )}
                            <div className={'cell small-offset-1 ' + (item.track ? 'small-10' : 'small-8') + ' ' + (item.track ? 'schedule-track' : 'schedule-text')}>
                                {item.name[this.props.language]}
                                {item.author && (
                                    <span>
                                        <br/>
                                        <em>
                                            {item.author[this.props.language]}
                                        </em>
                                    </span>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        )
    }
}

export default Schedule
