import React from "react";
import {graphql} from "gatsby";
import TeamsTable from "../components/final/teamsTable";
import {useI18next} from "gatsby-plugin-react-i18next";
import PartnersGrid from "../components/partnersGrid/partnersGrid";
import Streams from "../components/final/streams";
import Schedule from "../components/final/schedule";
import Scoreboard from "../components/final/scoreboard";
import Seo from "../components/seo/seo";

const Final = ({data, location}) => {
    const {t, language} = useI18next()
    const title = data.ctfJson.title
    const titleHeader = title.toUpperCase()
    const text = data.ctfJson.ctfText[language]
    const teamsSections = data.ctfJson.teamsSections
    const partnersSections = data.ctfJson.partnersSections
    const streams = data.ctfJson.streams
    const schedulePages = data.ctfJson.schedule
    const scoreboard = data.ctfJson.scoreboard
    const activity = data.ctfJson.activity
    const current = data.ctfJson.current
    const ctf = current ? data.ctfJson : null
    const showTeamStatus = data.ctfJson.showTeamStatus
    const showStreams = data.ctfJson.showStreams
    return (
        <>
            <Seo lang={language} description={text} title={title} pathname={location.pathname} ctf={ctf}/>
            <section className="welcome bg-first">
                <div className="grid-x">
                    <div className="large-offset-1 large-3 cell cell-text">
                        <h1>{titleHeader}</h1>
                        {text}
                        <hr/>
                    </div>
                </div>
            </section>
            <section>
                {showStreams && streams && streams.length > 0 && (
                    <div className="grid-x">
                        <div className="large-offset-1 large-4 cell cell-text">
                            <h1>{t("streams_header")}</h1>
                        </div>
                        <div className="large-offset-1 large-10 cell cell-text">
                            <Streams streams={streams} language={language}/>
                        </div>
                    </div>
                )}
            </section>
            <section>
                {schedulePages && (
                    <div className="grid-x">
                        <div className="large-offset-1 large-4 cell cell-text">
                            <h1>{t('schedule_header')}</h1>
                        </div>
                        <div className="medium-offset-4 medium-4 cell">
                            <Schedule pages={schedulePages} language={language}/>
                        </div>
                    </div>
                )}
            </section>
            <section>
                {activity && activity.show && (
                    <div className="grid-x">
                        <div className="large-offset-1 large-4 cell cell-text">
                            <h1>{t('activity_header')}</h1>
                            {activity.activityText[language]}
                            <br/>
                            <a href={activity.activityHref} target="_blank"
                               rel="noopener noreferrer">{activity.activityHref}</a>
                        </div>
                    </div>
                )}
            </section>
            <section>
                {scoreboard && (
                    <div className="grid-x">
                        <div className="large-offset-1 large-4 cell cell-text">
                            <h1>{t('scoreboard_header')}</h1>
                        </div>
                        <div className="large-offset-1 large-10 cell cell-text">
                            <Scoreboard scoreboard={scoreboard}/>
                        </div>
                    </div>
                )}
            </section>
            <section>
                {teamsSections && (
                    <div className="grid-x">
                        <div className="large-offset-1 large-4 cell cell-text">
                            <h1>{t("teams_header_1")}</h1>
                            <h1>{t("teams_header_2")}</h1>
                        </div>
                        <div className="large-offset-1 large-10 cell cell-text">
                            <TeamsTable teamsSections={teamsSections} showStatus={showTeamStatus} language={language}/>
                        </div>
                    </div>
                )}
            </section>
            <section>
                {partnersSections && (
                    <div className="grid-x">
                        <div className="large-offset-1 large-4 cell cell-text">
                            <h1>{t("partners_header_1")}</h1>
                            <h1>{t("partners_header_2")}</h1>
                        </div>
                        <div className="large-offset-1 large-10 cell">
                            <PartnersGrid partnersSections={partnersSections} language={language}/>
                        </div>
                    </div>
                )}
            </section>
        </>
    )
}

export const query = graphql`
    query Finals($id:String!, $language: String!){
        locales: allLocale(filter: {language: {eq: $language}}){
            edges{
                node{
                    ns
                    data
                    language
                }
            }
        }
        ctfJson(id: {eq: $id}){
            title
            current
            startDate
            endDate
            ctfText{
                en
                ru
            }
            showTeamStatus
            showStreams
            teamsSections{
                id,
                title{
                    en
                    ru
                }
                teams{
                    title
                    ctfTimeLink
                    edu{
                        en
                        ru
                    }
                    status{
                        color
                        en
                        ru
                    }
                }
            }
            streams {
                title{
                    en
                    ru
                }
                href
            }
            activity{
                activityText{
                    en
                    ru
                }
                activityLink
                show
            }
            schedule{
                date
                schedule{
                    author{
                        en
                        ru
                    }
                    name{
                        en
                        ru
                    }
                    time
                    track
                }
            }
            scoreboard{
                pos
                team
                score
            }
            partnersSections{
                id
                title{
                    en
                    ru
                }
                partners{
                    id
                    href
                    image{
                        publicURL
                    }
                }
            }
        }
    }
`

export default Final
