import React from "react";
import PartnersSectionHeader from "./partnersSectionHeader";
import PartnersImage from "./partnersImage";

const PartnersGrid = ({partnersSections, language}) => {
    if (!partnersSections) {
        return null
    }
    return (
        <div className="grid-x">
            {partnersSections.map(section => (
                <div key={section.id} className="large-12 grid-x">
                    <div className="large-12 cell cell-text">
                        <PartnersSectionHeader section={section} language={language}/>
                    </div>
                    {section.partners.map(partner => (
                        <div className="large-3 small-6 text-center" key={partner.id}>
                            <PartnersImage partner={partner}/>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
}

export default PartnersGrid
