import React from "react";
import {useTranslation} from "gatsby-plugin-react-i18next";

const Scoreboard = ({scoreboard}) => {
    const {t} = useTranslation()
    return (
        <div className="table-scroll">
            <table className="unstriped">
                <thead>
                <tr>
                    <th>#</th>
                    <th>{t("scoreboard_team")}</th>
                    <th>{t("scoreboard_score")}</th>
                </tr>
                </thead>
                <tbody>
                {scoreboard.map(team =>
                    <tr key={team.pos}>
                        <td>{team.pos}</td>
                        <td>{team.team}</td>
                        <td>{team.score < 1 && team.score > 0 ? (team.score * 100).toFixed(2) + "%" : team.score}</td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    )
}

export default Scoreboard
