import React from "react";
import {Helmet} from "gatsby-plugin-react-i18next";
import {graphql, useStaticQuery} from "gatsby";
import OpenGraph from "./og";

const Seo = ({title, description, pathname, lang, ctf, metaYandexVerification}) => {
    const {site} = useStaticQuery(query)
    const {
        siteMetadata: {
            siteUrl,
            defaultTitle,
            defaultDescription,
            banner,
            twitter
        }
    } = site

    const seo = {
        title: title ? `${defaultTitle}::${title}` : defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${banner}`,
        url: `${siteUrl}${pathname}`
    }

    let schemaEvent = null

    if (ctf) {
        schemaEvent = {
            "@context": "https://schema.org",
            "@type": "Event",
            name: title,
            startDate: ctf.startDate,
            endDate: ctf.endDate,
            eventAttendanceMode: "https://schema.org/OfflineEventAttendanceMode",
            eventStatus: "https://schema.org/EventScheduled",
            location: [{
                "@type": "VirtualLocation",
                url: seo.url
            }, {
                "@type": "Place",
                name: "Lotte Hotel Samara",
                address: {
                    "@type": "PostalAddress",
                    addressCountry: "RU",
                    addressRegion: "Самарская область",
                    addressLocality: "Самара",
                    streetAddress: "ул. Самарская, 110",
                    postalCode: "443041"
                }
            }],
            image: [
                seo.image
            ],
            description: seo.description,
            organizer: {
                "@type": "Organization",
                name: defaultTitle,
                url: siteUrl
            }
        }
    }

    return (
        <>
            <Helmet title={seo.title} defer={false}>
                <html lang={lang} prefix="og: http://ogp.me/ns#"/>
                <meta name="description" content={seo.description}/>
                <meta name="image" content={seo.image}/>
                {metaYandexVerification && <meta name="yandex-verification" content={metaYandexVerification}/>}
                {ctf && <script type="application/ld+json">{JSON.stringify(schemaEvent)}</script>}
            </Helmet>
            <OpenGraph
                title={title}
                description={seo.description}
                url={seo.url}
                image={seo.image}
                twitter={twitter}
                locale={lang}/>
        </>
    )
}

export default Seo

const query = graphql`
    query SEO
    {
        site
        {
            siteMetadata
            {
                siteUrl
                defaultTitle: title
                defaultDescription: description
                banner
                twitter
            }
        }
    }
`
