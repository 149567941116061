import React from "react";
import {Helmet} from "gatsby-plugin-react-i18next";

const OpenGraph = ({locale, url, title, description, image, twitter}) => (
    <Helmet>
            <meta property="og:locale" content={locale}/>
            <meta property="og:url" content={url}/>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content={title}/>
            <meta property="og:description" content={description}/>
            <meta property="og:image" content={image}/>
            <meta property="og:image:alt" content={description}/>
            <meta property="twitter:card" content="summary"/>
            <meta property="twitter:site" content={twitter}/>
            <meta property="twitter:title" content={title}/>
            <meta property="twitter:description" content={description}/>
            <meta property="twitter:image" content={image}/>
            <meta name="twitter:image:alt" content={description}/>
    </Helmet>
)

export default OpenGraph
