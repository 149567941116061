import React from "react";

const Streams = ({streams, language}) => {
    if (!streams) {
        return null
    }
    return (
        <React.Fragment>
            {streams.map(s => (
                <div className="grid-x" key={s.href}>
                    <div className="large-4 cell cell-text">
                        <h2>{s.title[language].toUpperCase()}</h2>
                    </div>
                    <div className="large-offset-3 large-6 cell">
                        <div className="flex-video">
                            <iframe frameBorder="0" width="100%" height="100%" scrolling="no" allowFullScreen="true"
                                    title={s.title[language]}
                                    src={s.href} allow="encrypted-media"/>
                        </div>
                    </div>
                </div>
            ))}
        </React.Fragment>
    )
}

export default Streams
