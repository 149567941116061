import React from "react";

const TeamsTable = ({showStatus, teamsSections, language}) => {
    return (
        <div className="table-scroll">
            <table className="teams unstriped">
                <tbody>
                {teamsSections.map(teamsSection => (
                    <React.Fragment key={teamsSection.id}>
                        <tr>
                            <th colSpan={showStatus ? "3" : "2"}>
                                {teamsSection.title[language]}
                            </th>
                        </tr>
                        {teamsSection.teams.map(team => (
                            <tr key={team.title}>
                                <td>
                                    <a href={team.ctfTimeLink} target="_blank" rel="noreferrer noopener">
                                        {team.title}
                                    </a>
                                </td>
                                <td>
                                    {team.edu[language]}
                                </td>
                                {showStatus && (
                                    <td style={{color: team.status.color || 'black'}}>
                                        {team.status[language]}
                                    </td>
                                )}
                            </tr>
                        ))}
                    </React.Fragment>
                ))}
                </tbody>
            </table>
        </div>
    )
}

export default TeamsTable
